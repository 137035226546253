
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class InventoryList extends Vue {
  // 仓库
  private wareHouseList = []

  private materialTypeData = [] // 物料类型

  private info = {
    projectId: '',
    // 仓库
    warehouseId: '',
    // 物料
    materialTypeId: '',
    materialName: ''
  }

  private tableData = {
    loading: false,
    data: []
  }

  private page = 1
  private size = 10
  private total = 0

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadMaterialType() // 物料类型
    this.getData()
  }

  loadMaterialType () {
    // this.loading = true
    this.$axios.get(this.$apis.material.selectYhMaterialTypeByPage).then(res => {
      this.materialTypeData = res.list
      // this.total = res.total
    }).finally(() => {
      // this.loading = false
    })
  }

  getData () {
    this.tableData.loading = true
    this.$axios.get(this.$apis.material.selectYhMaterialByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData.data = res.list || []
    }).finally(() => {
      this.tableData.loading = false
    })
  }

  // 仓库
  getWareHouseList () {
    this.info.warehouseId = ''
    this.$axios.get(this.$apis.material.selectYhWarehouseByList, { projectId: this.info.projectId }).then(res => {
      this.wareHouseList = res.list || []
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }
}
